document.addEventListener("DOMContentLoaded", function () {
  /*=============== SCROLL SECTIONS ACTIVE LINK ===============*/

  const sections = document.querySelectorAll("section[id]");

  const scrollActive = () => {
    const scrollY = window.scrollY;

    sections.forEach((current) => {
      const sectionHeight = current.offsetHeight;
      const sectionTop = current.offsetTop - 58;
      const sectionId = current.getAttribute("id");
      const sectionLink = document.querySelector(
        `.nav__menu a[href*="${sectionId}"]`
      );

      sectionLink?.classList.toggle(
        "active",
        scrollY > sectionTop && scrollY <= sectionTop + sectionHeight
      );
    });
  };

  window.addEventListener("scroll", scrollActive);

  let previousValues = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  function updateCountdown() {
    const weddingDate = new Date("2024-08-08T00:00:00");
    const now = new Date();
    const difference = weddingDate - now;

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    updateElement("days", days);
    updateElement("hours", hours);
    updateElement("minutes", minutes);
    updateElement("seconds", seconds);

    previousValues = { days, hours, minutes, seconds };
  }

  function updateElement(id, newValue) {
    const element = document.getElementById(id);
    if (parseInt(element.textContent, 10) !== newValue) {
      element.textContent = newValue;
      element.style.transition = "none";
      element.style.opacity = "0";
      element.style.transform = "translateY(-10px)";

      setTimeout(() => {
        element.style.transition = "opacity 0.5s ease, transform 0.5s ease";
        element.style.opacity = "1";
        element.style.transform = "translateY(0)";
      }, 50);
    }
  }

  setInterval(updateCountdown, 1000);

  const swiperHomeMobile = new Swiper(".home__swiper", {
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: "auto",
    slidesPerGroup: 1,
    loop: false,

    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });

  const swiperRequestMobile = new Swiper(".request__swiper", {
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: "auto",
    slidesPerGroup: 1,
    loop: false,

    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });

  /*=============== SHOW SCROLL UP ===============*/

  const scrollUp = () => {
    const scrollUp = document.getElementById("scroll-up");
    scrollUp?.classList.toggle("show-scroll", window.scrollY >= 350);
  };

  window.addEventListener("scroll", scrollUp);

  /*=============== SCROLL REVEAL ANIMATION ===============*/

  const sr = ScrollReveal({
    origin: "top",
    distance: "60px",
    duration: 2500,
    delay: 400,
    reset: false,
  });

  sr.reveal(
    ".home__title, .place__card-first, .place__data, .home__section-mobile, .request__section-mobile"
  );
  sr.reveal(".nav__menu", { delay: 500 });
  sr.reveal(".request__cards-second, .footer__content", { origin: "bottom" });
  sr.reveal(".home__font, .countdown__item, .countdown__separator", {
    interval: 100,
  });
  sr.reveal(
    ".home__image-item-second, .home__image-item-fourth, .place__card-third, .request__cards-first, .home__btn",
    { origin: "right" }
  );
  sr.reveal(
    ".home__image-item-first, .home__image-item-third, .place__card-second, .request__data, .request__cards-third, .home__logo",
    { origin: "left" }
  );
});
